<template>
    <section class="my-5">
        <p class="text-general f-600 f-16 my-3">
            Entregas por cedis en los últimos 30 días
        </p>
        <div class="row mx-0 my-2">
            <div class="col-3 px-0 f-600 text-general f-18">
                No. de entregas
            </div>
            <el-tooltip placement="bottom" effect="light">
                <div slot="content" style="width:180px;">
                    <div v-for="(barra, b) in cedisEntregas" :key="b" class="row mx-0">
                        <div class="col-12 d-middle py-1">
                            <div class="rounded-circle" :style="`backgroundColor:${barra.color}`" style="width:12px;height:12px;" /> <span class="text-general f-600 mx-1"> {{ barra.nombre }}</span>
                        </div>
                        <p class="col-auto text-general2">
                            {{ barra.porcentaje_cantidad }}%
                        </p>
                        <p class="col-auto text-general2">
                            {{ barra.cantidad }}
                        </p>
                    </div>
                </div>
                <div class="col d-flex" style="height:30px;">
                    <div
                    v-for="(data, d) in cedisEntregas"
                    :key="d"
                    :class="`h-100 ${d == 0 ? 'br-l-5' : d == cedisEntregas.length - 1 ? 'br-r-5' : ''}`"
                    :style="`backgroundColor:${data.color};width:${ agregarSeparadoresNumero(data.porcentaje_cantidad) }%;`"
                    />
                </div>
            </el-tooltip>
        </div>
        <div class="row mx-0 my-2">
            <div class="col-3 px-0 f-600 text-general f-18">
                Valor entregas
            </div>
            <el-tooltip placement="bottom" effect="light">
                <div slot="content" style="width:180px;">
                    <div v-for="(barra, b) in cedisEntregas" :key="b" class="row mx-0">
                        <div class="col-12 d-middle py-1">
                            <div class="rounded-circle" :style="`backgroundColor:${barra.color}`" style="width:12px;height:12px;" /> <span class="text-general f-600 mx-1"> {{ barra.nombre }}</span>
                        </div>
                        <p class="col-auto text-general2">
                            {{ barra.porcentaje_dinero }}%
                        </p>
                        <p class="col-auto text-general2">
                            {{ barra.dinero }}
                        </p>
                    </div>
                </div>
                <div class="col d-flex" style="height:30px;">
                    <div
                    v-for="(data, d) in cedisEntregas"
                    :key="d"
                    :class="`h-100 ${d == 0 ? 'br-l-5' : d == cedisEntregas.length - 1 ? 'br-r-5' : ''}`"
                    :style="`backgroundColor:${data.color};width:${ agregarSeparadoresNumero(data.porcentaje_dinero) }%;`"
                    />
                </div>
            </el-tooltip>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        cedisEntregas: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    // data(){
    //     return {
    //         barras: [
    //             {
    //                 width: 25,
    //                 color: 'bg-orange',
    //                 cedis: 'Bucaramanga'
    //             },
    //             {
    //                 width: 10,
    //                 color: 'bg-blue',
    //                 cedis: 'Horeca'
    //             },
    //             {
    //                 width: 15,
    //                 color: 'bg-purple',
    //                 cedis: 'Hogar'
    //             },
    //             {
    //                 width: 50,
    //                 color: 'bg-yellow',
    //                 cedis: 'Tienda a Tienda'
    //             }
    //         ]
    //     }
    // }
}
</script>
